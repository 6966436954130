<template>
    <PageHeader :title="title"/>
    <b-card>
        <b-row class="m-5">
            <p class="text-success mb-3 fs-18 fw-semibold">{{ $t('SpecifyFOPData') }}</p>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('Provider') }}</p>
                <input class="form-control" v-model="form.FOP.provider" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('checkingAccount') }}</p>
                <input class="form-control" v-model="form.FOP.calculatedWallet" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('title_bank') }}</p>
                <input class="form-control" v-model="form.FOP.bank" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">МФО</p>
                <input class="form-control" v-model="form.FOP.mfi" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">ЄРДПОУ</p>
                <input class="form-control" v-model="form.FOP.erdpu" />
            </b-col>
        </b-row>
        <hr />
        <b-row class="m-5">
            <p class="text-success mb-3 fs-18 fw-semibold">{{ $t('SpecifyTovData') }}</p>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('Provider') }}</p>
                <input class="form-control" v-model="form.TOV.provider" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('title_bank') }}</p>
                <input class="form-control" v-model="form.TOV.bank" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">IBAN</p>
                <input class="form-control" v-model="form.TOV.iban" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('address') }}</p>
                <input class="form-control" v-model="form.TOV.address" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">{{ $t('telephone') }}</p>
                <input class="form-control" v-model="form.TOV.phone" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">ЄРДПОУ</p>
                <input class="form-control" v-model="form.TOV.erdpu" />
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-14 fw-semibold">ІПН</p>
                <input class="form-control" v-model="form.TOV.ipn" />
            </b-col>
        </b-row>
        <hr />
        <b-row class="m-5">
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-16 fw-semibold">{{ $t('enteringTheCostOfGoods') }}</p>
                <select class="form-control" v-model="form.currency.enteringTheCostOfGoods">
                    <option v-for="item in currencies" :key="item" :value="item.iso">{{ item.name }}</option>
                </select>
            </b-col>
            <b-col lg="6" class="mb-3">
                <p class="text-muted mb-2 fs-16 fw-semibold">{{ $t('conclusionOfGoods') }}</p>
                <select class="form-control" v-model="form.currency.conclusionOfGoods">
                    <option v-for="item in currencies" :key="item" :value="item.iso">{{ item.name }}</option>
                </select>
            </b-col>
            <b-col lg="6" class="mb-3" v-if="form.currency.enteringTheCostOfGoods == 'USD'">
                <p class="text-muted mb-2 fs-16 fw-semibold">{{ $t('SpecifyCourse') }}</p>
                <div class="input-group">
                    <span class="input-group-text">$</span>
                    <input type="text" class="form-control" v-model="form.currency.course.USD">
                </div>
            </b-col>
        </b-row>
        <button 
            type="button" 
            class="btn btn-success ms-5 mb-3" 
            @click="save"
        >
            {{ $t('save') }}
        </button>
    </b-card>
</template>

<script>
import PageHeader from "@/components/page-header";
import { EcoSetting } from '@/API'

let apiServe = new EcoSetting();

export default({
    components: {
        PageHeader
    },
    data(){
        return{
            title: this.$t('Settings'),
            form: {
                FOP: {
                    provider: "",
                    bank: "",
                    erdpu: "",
                    mfi: "",
                    calculatedWallet: ""
                },
                TOV: {
                    provider: "",
                    bank: "",
                    erdpu: "",
                    iban: "",
                    phone: "",
                    address: "",
                    ipn: ""
                },
                currency: {
                    enteringTheCostOfGoods: '',
                    conclusionOfGoods: '',
                    course: {
                        USD: ''
                    }
                }
            },
            currencies: [
                {
                    name: '₴',
                    iso: 'UAH'
                },
                {
                    name: '$',
                    iso: 'USD'
                },
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            apiServe.getSettings().then(result => {
                if(result.status == 'done'){
                    this.form = result.data;
                } else {
                    this.$toast.error(this.$t('error') + " #SettingShop");
                }
            })
        },
        save(){
            apiServe.saveSettings(this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('changeswereSuccessful'));
                }
            })
        }
    }
    
})

</script>